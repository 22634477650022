import { SkillCategory } from "@/types/Skills.type";

const FrontEndSkills: SkillCategory = {
  name: "Front-end Development",
  skills: [
    {
      name: "VueJS (v3 & v2+)",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg",
      rating: 80,
    },
    {
      name: "ReactJS",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
      rating: 75,
    },
    {
      name: "Tailwind CSS",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
      rating: 70,
    },
    {
      name: "jQuery",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-original.svg",
      rating: 70,
    },
  ],
};

const BackEndSkills: SkillCategory = {
  name: "Back-end Development",
  skills: [
    {
      name: "Node.JS (+TS)",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
      rating: 70,
    },
    {
      name: "Laravel",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/laravel/laravel-plain.svg",
      rating: 80,
    },
    {
      name: "Django",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg",
      rating: 70,
    },
    {
      name: "Spring Boot",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original.svg",
      rating: 70,
    },
    {
      name: "WordPress",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wordpress/wordpress-plain.svg",
      rating: 60,
    },
  ],
};

const DbSkills: SkillCategory = {
  name: "Databases",
  skills: [
    {
      name: "MySQL",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg",
      rating: 60,
    },
  ],
};

// const OsKnowledge: SkillCategory = {
//   name: "OS",
//   skills: [
//     {
//       name: "Ubuntu",
//       icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/ubuntu/ubuntu-plain.svg",
//       rating: 70,
//     },
//     {
//       name: "Windows",
//       icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/windows8/windows8-original.svg",
//       rating: 65,
//     },
//   ],
// };

const LanguageKnowledge: SkillCategory = {
  name: "Programming Languages",
  skills: [
    {
      name: "JavaScript",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
      rating: 70,
    },
    {
      name: "PHP",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg",
      rating: 65,
    },
    {
      name: "Java",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg",
      rating: 70,
    },
    {
      name: "Python",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
      rating: 60,
    },
    {
      name: "Typescript",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
      rating: 60,
    },
  ],
};

export const SoftwareSkills: SkillCategory = {
  name: "Softwares",
  skills: [
    {
      name: "VS Code",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg",
      rating: 70,
    },
    {
      name: "Git",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg",
      rating: 70,
    },
    {
      name: "JIRA",
      icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jira/jira-original.svg",
      rating: 60,
    }
  ],
};

export const TechnicalSkills: Array<SkillCategory> = [
  FrontEndSkills,
  BackEndSkills,
  DbSkills,
  // OsKnowledge,
  SoftwareSkills,
  LanguageKnowledge,
];
