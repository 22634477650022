
import { defineComponent } from "vue";
import { TechnicalSkills } from "../config/skills";

export default defineComponent({
  data() {
    return {
      TechnicalSkills,
      showingCategory: ""
    };
  },
  methods: {
    showCategory(category: string) {
      if(this.showingCategory == category) {
        this.showingCategory = "";
      } else {
        this.showingCategory = category;
      }
    }
  }
});
